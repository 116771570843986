// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
// Подключение Swiper и необходимых модулей
import Swiper, { Navigation, Pagination, Autoplay,  } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

// Инициализация других слайдеров
const catLeft = new Swiper('.item-left', {
  slidesPerView: "auto",
  spaceBetween: 16,
  loop: true,
  centeredSlides: true,
  autoplay: {
    disableOnInteraction: false,
  },
  speed: 3500
});

const catRight = new Swiper('.item-right', {
  slidesPerView: "auto",
  spaceBetween: 16,
  loop: true,
  autoplay: {
    disableOnInteraction: false,
    reverseDirection: true,
  },
  speed: 3500
});

// const recommends = new Swiper('.recommend__slider', {
//   slidesPerView: 4,
//   spaceBetween: 20,
//   loop: false,
//   centeredSlides: false,
//   breakpoints: {
//     1023: {
//       slidesPerView: 4,
//       spaceBetween: 20,
//       centeredSlides: true,
//     }
//   }
// });

// Функция для инициализации слайдера
function initSlider(selector) {
  return new Swiper(selector, {
    slidesPerView: 1.3,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      280: {
        slidesPerView: 1.4,
        spaceBetween: 16,
      }
    }
  });
}

let recommendSwiper;
let widgetSwiper;
let userLineSwiper;
function initSwipers() {
  if (window.innerWidth <= 1023) {
    if (!recommendSwiper) {
      recommendSwiper = initSlider('.recommend__slider');
    }
    if (!widgetSwiper) {
      widgetSwiper = initSlider('.widget__slider');
    }
    if (!userLineSwiper) {
      userLineSwiper = initSlider('.userline__slider');
    }
  } else {
    if (recommendSwiper) {
      recommendSwiper.destroy();
      recommendSwiper = null;
    }
    if (widgetSwiper) {
      widgetSwiper.destroy();
      widgetSwiper = null;
    }
    if (userLineSwiper) {
      userLineSwiper.destroy();
      userLineSwiper = null;
    }
  }
}

// Инициализация при загрузке страницы
initSwipers();

// Добавление слушателя события изменения размера окна
window.addEventListener('resize', initSwipers);


// Подключение анимаций по скроллу
import AOS from 'aos';
AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.chat__picture');

// Подключение плавной прокрутки к якорям
import SmoothScroll from 'smooth-scroll';


// const scrollConnection = new SmoothScroll('a[href*="#connection"]', { offset: 300 });

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

import { validateForms } from './functions/validate-forms';


const checks = [
  {
    selector: "#consent",
    errorMessage: "Согласитесь с обработкой данных",
  }
];

const checksP = [
  {
    selector: "#pconsent",
    errorMessage: "Согласитесь с обработкой данных",
  }
];

const rules = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Введите корректное Имя'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон'
      }
    ]
  },
  {
    ruleSelector: '.input-email',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Введите корректный email'

      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  }
];
const rulesP = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Введите корректное Имя'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон'
      }
    ]
  },
  {
    ruleSelector: '.input-email',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Введите корректный email'

      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  }
];

const animationForm = lottie.loadAnimation({
  container: document.querySelector('.success__img'),
  renderer: 'svg',
  loop: false, // Анимация проигрывается один раз
  autoplay: false, // Анимация не проигрывается автоматически
  path: './stepLoad.json' // Путь к вашему JSON-файлу с анимацией
});

const animationFormP = lottie.loadAnimation({
  container: document.querySelector('.popup__wrapper .success__img'),
  renderer: 'svg',
  loop: false, // Анимация проигрывается один раз
  autoplay: false, // Анимация не проигрывается автоматически
  path: './stepLoad.json' // Путь к вашему JSON-файлу с анимацией
});

const afterForm = () => {
  document.querySelector('.form-page .form__success').classList.add('active')
  animationForm.goToAndPlay(0, true);
  console.log('Произошла отправка, тут можно писать любые действия');
};
const PafterForm = () => {
  document.querySelector('.popup__form .form__success').classList.add('active')
  animationFormP.goToAndPlay(0, true);
  console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.form-page', rules, checks, afterForm);
validateForms('.popup__form', rulesP, checksP, PafterForm);



import lottie from 'lottie-web';
import Inputmask from 'inputmask';


const header = document.querySelector('.header')
const burger = document.querySelector('.header__burger')
const burgerLine = burger.querySelector('.burger__line')
const menu = document.querySelector('.menu')
const menuContact = document.querySelector('a[href*="#footer"]')
const menuPrice = menu.querySelector('a[href*="#price"]')
const isMobile = window.innerWidth <= 767;
const scrollPrice = new SmoothScroll('a[href*="#price"]', { offset: 150 });
const scrollContact = new SmoothScroll('a[href*="#footer"]', { duration: 300 });
burger.addEventListener('click', () => {
  burgerLine.classList.toggle('animate')
  menu.classList.toggle('active')
  header.classList.toggle('active')
})

function handleMenuClick(event) {
  if (isMobile) {
    burgerLine.classList.toggle('animate');
    menu.classList.toggle('active');
    header.classList.toggle('active');
  }
}

menuContact.addEventListener('click', handleMenuClick);
menuPrice.addEventListener('click', handleMenuClick);

const subMenu = document.querySelectorAll('.item-sub')

subMenu.forEach(item => {
  item.addEventListener('click', (evt) => {
    if(!evt.target.classList.contains('link-sub')) {
      evt.preventDefault()
      item.classList.toggle('active')
    }

  })
})


let rateItems = document.querySelectorAll('.rate__item');
let rateBtns = document.querySelectorAll('.rate__item .item__more');

rateBtns.forEach((item, index) => {
  item.addEventListener('click', () => {
    const activeItem = item.closest('.rate__item');
    const isTablet = window.innerWidth <= 1279 && window.innerWidth > 767;

    if (isMobile) {
      activeItem.classList.toggle('active');
      activeItem.classList.contains('active') ? item.innerHTML = 'скрыть' : item.innerHTML = 'показать еще';
    } else if (isTablet) {
      if (index <= 1) {
        rateItems.forEach((el, idx) => {
          if (idx <= 1) {
            toggleItemClass(el, 'active');
            updateButtonText(el, 'скрыть', 'показать еще');
          }
        });
      } else {
        rateItems.forEach((el, idx) => {
          if (idx === 2) {
            toggleItemClass(el, 'active');
            updateButtonText(el, 'скрыть', 'показать еще');
          }
        });
      }
    }
  });
});

function toggleItemClass(item, className) {
  item.classList.toggle(className);
}

function updateButtonText(item, hideText, showText) {
  item.querySelector('.item__more').innerHTML = item.classList.contains('active') ? hideText : showText;
}


if(isMobile) {
  let tariffItems = document.querySelectorAll('.tariff__item')
  let tariffToggle = document.querySelectorAll('.tariff__item.item-toggle')
  tariffToggle.forEach(item => {
    item.addEventListener('click', (e) => {
      tariffToggle.forEach(btn => btn.classList.remove('choose'))
      item.classList.add('choose')
      tariffItems.forEach(el => {
        if(el.dataset.month === item.dataset.value) {
          el.classList.add('active')
        } else {
          el.classList.remove('active')
        }
      })
    })
  })
  //works

  let worksSteps = document.querySelectorAll('.works .item__step')
  let worksToggle = document.querySelectorAll('.works .item__wrapper')
  worksSteps.forEach(item => {
    item.addEventListener('click', (e) => {
      worksSteps.forEach(btn => btn.classList.remove('choose'))
      item.classList.add('choose')
      worksToggle.forEach(el => {
        if(item.dataset.step === el.dataset.content) {
          el.classList.add('active')
        } else {
          el.classList.remove('active')
        }
      })
    })
  })
}




let stepInfo = document.querySelector('.step-info')
let stepFinal = document.querySelector('.step-final')
let form = document.querySelector('.step__form');
let inputs = document.querySelectorAll('.calc .form__input');
let lottieAnimation = document.querySelector('.calc__step.step-load');
inputs.forEach(input => {
  const formRow = input.closest('.form__row');

  input.addEventListener('focus', function() {
    if (formRow) {
      formRow.classList.add('focus');
    }
  });

  input.addEventListener('blur', function() {
    if (formRow) {
      if (input.value.trim() === '') {
        formRow.classList.remove('focus');
      }
    }
  });

  input.addEventListener('input', function() {
    input.value = input.value.replace(/\D/g, '');
  });

  // Check the input initially in case it is pre-filled
  if (formRow && input.value.trim() !== '') {
    formRow.classList.add('focus');
  }
});

const animation = lottie.loadAnimation({
  container: lottieAnimation,
  renderer: 'svg',
  loop: false, // Анимация проигрывается один раз
  autoplay: false, // Анимация не проигрывается автоматически
  path: './stepLoad.json' // Путь к вашему JSON-файлу с анимацией
});


form && form.addEventListener('submit', function(event) {
  event.preventDefault();
  let allValid = true;

  inputs.forEach(input => {
    const formRow = input.closest('.form__row');
    if (/^0+$/.test(input.value.trim())) {
      if (formRow) {
        formRow.classList.add('warn');
      }
      allValid = false;
    } else {
      if (formRow) {
        formRow.classList.remove('warn');
      }
    }
  });

  if (allValid) {
    const users = parseInt(form.querySelector('.input-users').value.trim(), 10);
    const bill = parseFloat(form.querySelector('.input-bill').value.trim());
    const orders = parseInt(form.querySelector('.input-orders').value.trim(), 10);

    const conversionOld = (orders / users) * 100;
    const conversionNew = conversionOld * 1.2;
    const orderDiff = (conversionNew / 100) * users - orders;
    const missedMoney = orderDiff * bill;

    document.querySelector('.step-final .result__value').innerHTML = Math.round(missedMoney).toLocaleString('ru');
    lottieAnimation.classList.add('visible')
    animation.goToAndPlay(0, true);
    animation.addEventListener('complete', function() {
      lottieAnimation.classList.remove('visible')
      stepFinal.classList.add('visible');
    });

  } else {
    stepInfo.classList.remove('done');
  }
});

const inputTels = document.querySelectorAll('.input-tel');
if (inputTels.length > 0) {
  const im = new Inputmask({
    mask: '+7 (999) 999-99-99',
    showMaskOnHover: false,
    showMaskOnFocus: true,
    clearIncomplete: true,
    autoUnmask: true
  });

  inputTels.forEach(inputTel => {
    im.mask(inputTel);
  });
}


let faqItemsQuestion = document.querySelectorAll('.faq__item .item__question')

faqItemsQuestion.forEach(item => {
  item.addEventListener('click', (e) => {
    e.preventDefault();
    item.closest('.faq__item').classList.toggle('active')
    let itemAnswer = item.nextElementSibling;
    console.log(itemAnswer)
    !itemAnswer.style.maxHeight ? itemAnswer.style.maxHeight = itemAnswer.scrollHeight + 'px' : itemAnswer.style.maxHeight = null;
  })
})



// Получаем все элементы с data-popup='true'
const popupElements = document.querySelectorAll('[data-popup="true"]');
const popup = document.querySelector('.popup');
const popupWrapper = popup.querySelector('.popup__wrapper');
const popupClose = document.querySelector('.popup__close');
const pageUrl = document.querySelectorAll('.input-url')
const pageTarget = document.querySelector('.popup .input-target')
let preventClose = false;

pageUrl.forEach(inp => inp.value = window.location.href)



// Добавляем обработчик события клика на каждый элемент
popupElements.forEach(element => {
  element.addEventListener('click', event => {
    event.preventDefault();
    preventClose = true;
    let target = element.dataset.target
    console.log(target)

    pageTarget.value = target;
    popup.classList.add('active');
    document.body.classList.add('no-scroll'); // Блокируем прокрутку
  });
});

// Обработчик закрытия popup окна
popupClose.addEventListener('click', event => {
  event.preventDefault();
  popup.classList.remove('active');
  document.body.classList.remove('no-scroll'); // Разблокируем прокрутку
});

//Обработчик клика вне popup окна для его закрытия
document.addEventListener('click', (e) => {
  if (e.target.classList.contains('active') && !preventClose && !e.target.classList.contains('.form__input')) {
    popup.classList.remove('active');
    document.body.classList.remove('no-scroll');
  }
  preventClose = false;
});

document.addEventListener('keydown', function(e) {
  if( e.keyCode == 27 ){
    popup.classList.remove('active');
    document.body.classList.remove('no-scroll');
  }
});

// Предотвращаем закрытие popup при клике внутри контента popup
// popupWrapper.addEventListener('click', (e) => {
//   preventClose = true;
// });




// var acc = document.getElementsByClassName("accordion");
// var i;
//
// for (i = 0; i < acc.length; i++) {
//   acc[i].addEventListener("click", function() {
//     this.classList.toggle("active");
//     var panel = this.nextElementSibling;
//     if (panel.style.maxHeight) {
//       panel.style.maxHeight = null;
//     } else {
//       panel.style.maxHeight = panel.scrollHeight + "px";
//     }
//   });
// }


let demoWidget = document.querySelectorAll('.btn-demo')

demoWidget?.forEach(el => {
  el.addEventListener('click', () => {
      document.getElementById('canihave_widget').click();
      document.querySelector('.widget_open').classList.add('hide')
      document.querySelector('.canihave_widget_question').classList.add('_close')
    })
})

let demoChat = document.querySelector('.btn-chat')

demoChat?.addEventListener('click', () => {
  document.getElementById('canihave_widget_ai').click();
  document.querySelector('.widget_open').classList.add('hide')
  document.querySelector('.canihave_widget_question').classList.add('_close')
})


let ofertaBtn = document.querySelectorAll('.tabs__btn')
let ofertaTabAg = document.querySelector('#tab1')
let ofertaTabMain = document.querySelector('#tab2')
ofertaBtn.forEach( (item, i) => {
  item.addEventListener('click', () => {
    ofertaTabAg.classList.toggle('active')
    ofertaTabMain.classList.toggle('active')
  })
})
